import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import typography from '../utils/typography'
import Seo from '../components/seo'


const video = {
  margin: `0 -${typography.rhythm(1)}`,
  marginTop: `-${typography.rhythm(1.5)}`,
  maxWidth: typography.rhythm(30),
}


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter } = markdownRemark
  return (
    <>
      <Seo title={frontmatter.title} />
      <Layout>
        <div css={video}>
        <div className="video">
          <iframe
            title="Mundi Dance Video"
            allowFullScreen="1"
            frameBorder="0"
            scrolling="no" width="100%" height="100%" type="text/html" src="https://www.youtube.com/embed/6NbxBYX1-K0?autoplay=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&vq=hd720">
          </iframe>
        </div>
        </div>
      </Layout>
    </>
  )
}

export const homeQuery = graphql`
  query homepageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      html
    }
  }
`